.popup-content {
  margin: auto;
  background: linear-gradient(180deg, #1C263D, #1C3251);
  width: 40%;
  padding: 10px;
  border-radius: 10px;
  color: white;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@media (max-width: 768px) {
    .popup-content {
  width: 70%;
}
}