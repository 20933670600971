.main {
    width: 90%;
    height: 260px;
    position : relative;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 70px;
}

.main > p {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: left;
    position: relative;
    justify-content: left;
    color: #0C2037;
    margin: 5px;
}

.slick-slide > div {
    margin: 10px 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .slick-slide > div {
        margin: 10px 10px;
    }
} 

@media screen and (max-width: 600px) {
    .slick-slide > div {
        margin: 10px 25px;
    }
}

.slick-list {
    margin: 0 -10px;
}

.card {
    /*border: 1px solid #fff;
    background: rgb(240 240 240 / 80%);
    border-radius: 8px;
    overflow: hidden;

    height: 400px;*/
    width: 240px;
    height: 250px;
    background: rgb(240 240 240 / 80%);
    border-radius: 10px;
    display: inline-block;
    margin-left: 25px;
    margin-right: 25px;
    box-shadow: 2px 2px 2px 2px #0000001f;
    /*cursor: pointer;*/
}

.card-image {
    width: 100%;
    height: 220px;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.card-title {
    font-weight: 630;
    margin-top: 5px;
    display: flex;
    align-items: center;
    position: center;
    justify-content: center;
    color: #0C2037;
    margin: 5px;
}

.arrow.next, .arrow.prev {
    border-radius: 100%;
    border: 2px solid #fff;
    background-color: transparent;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 45%;
    z-index: 999;
}

.arrow.prev:hover, .arrow.next:hover {
    background: #fff;
    color: #777;
    opacity: 1;
}

.arrow.prev {
    left: 0;
}

.arrow.next {
    right: 0;
}
