.HomeProductsMain {
    width: 90%;
    height: 260px;
    position : relative;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.HomeProductsMain > p {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: left;
    position: relative;
    justify-content: left;
    color: #0C2037;
    margin: 5px;
}


.HomeProductsMain > a {
    font-weight: 550;
    font-size: 16px;
    display: flex;
    align-items: left;
    position: relative;
    justify-content: right;
    color: #0C2037;
    margin: 3px;
    opacity: 1;
}

.HomeProductsMain > a:hover {
    font-size: 18px;
}

.productcard {
    /*border: 3px solid #0C2037;*/
    width: 280px;
    height: 180px;
    background: rgb(240 240 240 / 80%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-right: 25px;
    box-shadow: 6px 0px 1px 1px #131826;
    cursor: pointer;
    overflow: hidden;
}

.productcard:hover {
    width: 290px;
    height: 190px;  
}

.productcard-image {
    width: 100%;
    height: 150px;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.productcard-image:hover {
    width: 100%;
    height: 160px;  
}

.productcard-title {
    font-weight: 630;
    display: flex;
    align-items: center;
    position: center;
    justify-content: center;
    color: #0C2037;
    margin: 2px;
}

.productcard-title:hover {
    font-weight: 680;

}

.slick-slide > div {
    margin: 10px 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .slick-slide > div {
        margin: 10px 10px;
    }
} 

@media screen and (max-width: 600px) {
    .slick-slide > div {
        margin: 10px 25px;
    }
}

.slick-list {
    margin: 0 -10px;
}
