.slider {
    width: 80%;
    height: 80vh;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 250px; 
    border-bottom-right-radius: 250px; 
    margin-top: 1px;
    margin-bottom: 5px;
    margin-right: auto;
    left: 0;
    justify-content: left;
    align-items: left;
    display: flex;
    box-shadow: 15px 0px 0px 0px #EF5626;

}

@media screen and (max-width: 600px) {
    .slider {
        width: 90%;
        height: 35vh;
        margin-right: auto;
    }
} 

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.5s ease;
}

@media screen and (min-width: 600px) {
    .slide img {
        width: 100%;
        height: 100%;
    }
} 

.slide img {
    height: 100%;
}


.content {
    position: absolute;
    top: 28rem;
    left: 5rem;
    opacity: 0;
    width: 50%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    animation: slide-up 1s ease 0.5s;
    animation-fill-mode: forwards;
    /*visibility: hidden;*/
}

.content > p {
    font-size: 28px;
    font-weight: 650;
}

@keyframes slide-up {
    0% {
        visibility: visible;
        top: 28rem;
    }
    100% {
        visibility: visible;
        top: 22rem;
    }
}

@media screen and (max-width: 600px) {
    .content {
        position: absolute;
        top: 12rem;
        left: 5rem;
        opacity: 0;
        width: 50%;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.3);
        animation: slide-up 1s ease 0.5s;
        animation-fill-mode: forwards;
        /*visibility: hidden;*/
    }
    .content > p {
        font-size: 18px;
        font-weight: 500;
    }
    @keyframes slide-up {
    0% {
        visibility: visible;
        top: 12rem;
    }
    100% {
        visibility: visible;
        top: 8rem;
    }
}
} 



.content > * {
    color: #fff;
    margin: auto;
}

.current {
    opacity: 1;
    transform: translateX(0);
}

.current .content {
    opacity: 1;
}

.btn {
    background-color: blue;
    border: none;
    border-radius: 10px 10px;
}

.arrowmain {
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 999;
    opacity: 50%;
}

.arrowmain:hover {
    background: #fff;
    color: #777;
}

.next {
    right: 1.5rem;
}

.prev {
    left: 1.5rem;
}

hr {
    height: 2px;
    background: #fff;
    width: 50%;
}